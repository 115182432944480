import React, { useState } from 'react'
import { useForm } from "../hooks/useForm";
import axios from "axios";
import "../assets/css/form.css"
import "../assets/css/radio.css"
import "../assets/css/dropdown.css"

const Form = () => {

    const utms = window.location.search.substring(1).split("&");
    const utm_source = utms[0] ? utms[0].split("=").pop() : "Organic";
    const utm_medium = utms[1] ? utms[1].split("=").pop() : "Organic";
    const utm_campaign = utms[2] ? utms[2].split("=").pop() : "Organic";
    const utm_term = utms[3] ? utms[3].split("=").pop() : "Organic";
    const utm_content = utms[4] ? utms[4].split("=").pop() : "Organic";

    const initialForm = {
      has_service:"",
      type_propriety:"",
      size_propriety:"",
      provincia:"",
      type_security:"",
      nombre:"",
      telefono:"",
      email:"",
    };

    const [currentStep, setCurrentStep] = useState(0);
    const [emptyError, setEmptyError] = useState({});
    const [formValues, handleInputChange, reset] = useForm(initialForm);


    const handleSelectNextClick = async (event) => {
      //setSelections({...selections, [event.target.name]:event.target.value})
      handleInputChange(event);
      setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePreviousClick = async (event) => {
        event.preventDefault(); // Prevent the default form submission action
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const validar_email = (email) => {
      return /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)
    }

    const validar_tel = (tel) => {
      return tel.length >= 8 && tel.length < 13
    }

    const handleSubmitClick = async (event) => {
        event.preventDefault();

        var err = {}
        for (let k in formValues){
          err = {...err, [k]:(formValues[k]==="")};
        }
        err["email"]=err["email"]||!validar_email(formValues.email)
        err["telefono"]=err["telefono"]||!validar_tel(formValues.telefono)

        setEmptyError(err)
        //console.log(formValues)

        if (Object.values(err).includes(true)){
          return;
        }else {


          let lead = {
            ...formValues,
            utm_medium_c: utm_medium,    
            utm_source_c: utm_source,
            utm_content_c: utm_content,
            utm_campaign_c: utm_campaign,
            utm_term_c: utm_term,

            status: "New",
            assigned_user_id: "821db49c-70f4-8040-d6f9-62ff989b79b5",
          }

          await axios({
            method: "POST",
            url: "processor.php",
            headers: {
              "Content-Type": "application/json",
            },
            data: lead,
          })
            .then((res) => {
              if (res.data.includes("success")) {
                //setCorrectValidation(1); // Seteo la variable en 1 para que en la pagina se muestre el mensaje de "Uno de nuestros representantes lo contactará a la brevedad."
                setCurrentStep((prevStep) => prevStep + 1);
                reset();
                //setLoading(false);
              } else {
                alert("Ha ocurrido un error inesperado!")
                //setCorrectValidation(5);
                //setLoading(false);
              }
            })
            .catch((err) => {
              console.warn(err);
                //setCorrectValidation(5); // "A ocurrido un error inesperado"
                alert("Ha ocurrido un error inesperado")
                //setLoading(false);
            });

          setTimeout(() => {
            setEmptyError({})
            //setCorrectValidation(0);
          }, 4000);
          return;
        }

    };
  return (
    <div className="row">
      <div className="col-md-8 col-md-offset-3">
        <form id="msform">
          <div style={{ display: currentStep < 6 ? 'block' : 'none' }}>
            <h1>LA ÚNICA ALARMA QUE TE PROTEGE DENTRO Y FUERA DEL HOGAR</h1>
            <ul id="progressbar">
              <li className={currentStep === 0 || currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5  ? 'active' : ''}></li>
              <li className={currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5  ? 'active' : ''}></li>
              <li className={currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5  ? 'active' : ''}></li>
              <li className={currentStep === 3 || currentStep === 4 || currentStep === 5  ? 'active' : ''}></li>
              <li className={currentStep === 4 || currentStep === 5  ? 'active' : ''}></li>
              <li className={currentStep === 5  ? 'active' : ''}></li>
            </ul>
          </div>

          {/* 1. Servicio actual */}
          <fieldset style={{ display: currentStep === 0 ? 'block' : 'none' }}>
            <h2 className="fs-title">1. Servicio actual</h2>
            <h3 className="fs-subtitle">¿Actualmente dispones de un sistema de alarma?</h3>

            <div className='selection-container'>

              <label className="selection_radio" htmlFor="1_no">No
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_no" name="has_service" value="no"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="1_si_prosegur">Sí, de Prosegur
                <input  onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_si_prosegur" name="has_service" value="si_de_prosegur"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="1_si_otra">Sí, de otra empresa
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_si_otra" name="has_service" value="si_de_otra_empresa"/>
                <span className="checkmark"></span>
              </label>

            </div>

          </fieldset>

          {/* 2. Tipo de propiedad */}
          <fieldset style={{ display: currentStep === 1 ? 'block' : 'none' }}>
            <h2 className="fs-title">2. Tipo de propiedad</h2>
            <h3 className="fs-subtitle">¿Cómo es tu vivienda?</h3>

            <div className='selection-container'>

              <label className="selection_radio" htmlFor="2_casa">Casa
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="2_casa" name="type_propriety" value="casa"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="2_depto">Departamento
                <input  onClick={handleSelectNextClick} className='radio_button' type="radio" id="2_depto" name="type_propriety" value="provincia"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="2_parcela">Parcela
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="2_parcela" name="type_propriety" value="parcela"/>
                <span className="checkmark"></span>
              </label>

            </div>

            <div className="back-button-container">
              <button className="previous action-button-previous" onClick={handlePreviousClick}>{"< Volver atrás"}</button>
            </div>

          </fieldset>

          {/* 3. Metros de la propiedad */}
          <fieldset style={{ display: currentStep === 2 ? 'block' : 'none' }}>
            <h2 className="fs-title">3. Metros de la propiedad</h2>
            <h3 className="fs-subtitle">¿Cuántos metros cuadrados tiene su propiedad?</h3>

            <div className='selection-container'>

              <label className="selection_radio" htmlFor="3_0_90">De 0 a 90m2
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="3_0_90" name="size_propriety" value="de_0_a_90m2"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="3_90_140">De 90 a 140m2
                <input  onClick={handleSelectNextClick} className='radio_button' type="radio" id="3_90_140" name="size_propriety" value="de_90_a_140m2"/>
                <span className="checkmark"></span>
              </label>

              <label className="selection_radio" htmlFor="3_140_mas">Más de 140m2
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="3_140_mas" name="size_propriety" value="mas_de_140m2"/>
                <span className="checkmark"></span>
              </label>

            </div>

            <div className="back-button-container">
              <button className="previous action-button-previous" onClick={handlePreviousClick}>{"< Volver atrás"}</button>
            </div>

          </fieldset>

          {/* 4. ¿Dónde está ubicada? */}
          <fieldset style={{ display: currentStep === 3 ? 'block' : 'none' }}>
            <h2 className="fs-title">4. ¿Dónde está ubicada?</h2>
            <br/>

            <div className='dropdown-container'>
              <select
                name="provincia"
                placeholder="Seleccione su provincia"
                value={formValues.provincia}
                onChange={handleSelectNextClick}
              >
                <option disabled hidden value="">Seleccionar Departamento</option>
                <option value="departamento_de_artigas">Artigas</option>
                <option value="departamento_de_canelones">Canelones</option>
                <option value="departamento_de_cerro_largo">Cerro Largo</option>
                <option value="departamento_de_colonia">Colonia</option>
                <option value="departamento_de_durazno">Durazno</option>
                <option value="departamento_de_corrientes">Flores</option>
                <option value="departamento_de_florida">Florida</option>
                <option value="departamento_de_lavalleja">Lavalleja</option>
                <option value="departamento_de_maldonado">Maldonado</option>
                <option value="departamento_de_montevideo">Montevideo</option>
                <option value="departamento_de_paysandu">Paysandú</option>
                <option value="departamento_de_rio_negro">Río Negro</option>
                <option value="departamento_de_rivera">Rivera</option>
                <option value="departamento_de_rocha">Rocha</option>
                <option value="departamento_de_salto">Salto</option>
                <option value="departamento_de_san_jose">San José</option>
                <option value="departamento_de_soriano">Soriano</option>
                <option value="departamento_de_tacuarembo">Tacuarembó</option>
                <option value="departamento_de_treinta_tres">Treinta y Tres</option>
              </select>
              <br/>

            </div>

            <div className="back-button-container">
              <button className="previous action-button-previous" onClick={handlePreviousClick}>{"< Volver atrás"}</button>
            </div>

          </fieldset>

          {/* 6. Tipo de seguridad */}
          <fieldset style={{ display: currentStep === 4 ? 'block' : 'none' }}>
            <h2 className="fs-title">5. Tipo de seguridad</h2>
              <h3 className="fs-subtitle">¿Le interesa la seguridad interior o exterior de su hogar?</h3>

              <div className='selection-container'>

                <label className="selection_radio" htmlFor="interior">Interior
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="interior" name="type_security" value="interior"/>
                  <span className="checkmark"></span>
                </label>

                <label className="selection_radio" htmlFor="exterior">Exterior
                  <input  onClick={handleSelectNextClick} className='radio_button' type="radio" id="exterior" name="type_security" value="exterior"/>
                  <span className="checkmark"></span>
                </label>

                <label className="selection_radio" htmlFor="interior_y_exterior">Interior y exterior
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="interior_y_exterior" name="type_security" value="interior_y_exterior"/>
                  <span className="checkmark"></span>
                </label>

              </div>

              <div className="back-button-container">
                <button className="previous action-button-previous" onClick={handlePreviousClick}>{"< Volver atrás"}</button>
              </div>

          </fieldset>

          {/* 7. Datos personales */}
          <fieldset style={{ display: currentStep === 5 ? 'block' : 'none' }}>
          <h2 className="fs-title">6. Datos personales</h2>
            <h3 className="fs-subtitle">Para poder acercarte una oferta necesitamos contar con tus datos:</h3>

            <div className='text-input-container'>

            <input
              required={true}
              className={emptyError.nombre?"error-text":''}
              type="text"
              name="nombre"
              placeholder={emptyError.nombre?"Llene el campo nombre":"Nombre"}
              value={formValues.nombre}
              onChange={handleInputChange}
              onBlur={() => setEmptyError({...emptyError, nombre:formValues.nombre===""})}
              onFocus={() => setEmptyError({...emptyError, nombre:false})}
              onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
            />

            <input
              required={true}
              className={emptyError.telefono?"error-text":''}
              type="number"
              name="telefono"
              placeholder={emptyError.telefono?"Llene el campo teléfono":"Teléfono"}
              value={formValues.telefono}
              onChange={handleInputChange}
              onBlur={() => setEmptyError({...emptyError, telefono:formValues.telefono===""})}
              onFocus={() => setEmptyError({...emptyError, telefono:false})}
              onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
            />

            <input
              required={true}
              className={emptyError.email?"error-text":''}
              type="email"
              name="email"
              placeholder={emptyError.email?"Llene el campo email":"Email"}
              value={formValues.email}
              onChange={handleInputChange}
              onBlur={() => setEmptyError({...emptyError, email:(formValues.email==="")})}
              onFocus={() => setEmptyError({...emptyError, email:false})}
              onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
            />

            </div>

            <div className="final-buttons">
              <button className="previous action-button-previous" onClick={handlePreviousClick}>{"< Volver atrás"}</button>
              <button className="submit action-button" onClick={handleSubmitClick}>Enviar</button>
            </div>
          </fieldset>
          <div style={{ display: currentStep === 6 ? 'block' : 'none' }}>
            <h4>¡MUCHAS GRACIAS!</h4>
            <h5>HEMOS RECIBIDO CORRECTAMENTE TU SOLICITUD</h5>
            <h6>En breve un asesor se contactará contigo.</h6>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form